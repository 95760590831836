var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('table-toolbar',{attrs:{"context":_vm.tableToolbarContext,"loading-data":_vm.loadingData,"expand-table-filters":_vm.expandTableFilters,"filters-are-active":_vm.filtersAreActive},on:{"update:expandTableFilters":function($event){_vm.expandTableFilters=$event},"update:expand-table-filters":function($event){_vm.expandTableFilters=$event},"refreshData":_vm.getTableData}}),_c('v-data-table',{style:(_vm.tableWrapStyle),attrs:{"loading":_vm.loadingData,"headers":_vm.headers,"items":_vm.tableData.items,"options":_vm.paginationOptions,"server-items-length":_vm.tableData.itemCount,"footer-props":{
        itemsPerPageOptions: _vm.paginationOptions.itemsRange,
        showCurrentPage: true,
        showFirstLastPage: true,
        disablePagination: _vm.loadingData,
        disableItemsPerPage: _vm.loadingData
      }},on:{"update:options":function($event){_vm.paginationOptions=$event}},scopedSlots:_vm._u([{key:"item.treatment",fn:function(ref){
      var item = ref.item;
return [(item.completedAt)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.$vuetify.icons.values.check)+" ")]):_c('v-icon',{attrs:{"color":"grey"}},[_vm._v(" "+_vm._s(_vm.$vuetify.icons.values.loading)+" ")])]}},{key:"item.schedule",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getScheduleString(item.schedule))+" ")]}},{key:"item.documentCreatedFrom",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateTimeValue(item.documentCreatedFrom, 'long'))+" ")]}},{key:"item.documentCreatedTo",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateTimeValue(item.documentCreatedTo, 'long'))+" ")]}},{key:"item.createdAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateTimeValue(item.createdAt, 'long'))+" ")]}},{key:"item.completedAt",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateTimeValue(item.completedAt, 'long'))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('table-button',{attrs:{"link":{
            name: 'DocumentReportsDetails',
            params: {
              routeDetailId: item.id
            }
          },"text":"general.details"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }