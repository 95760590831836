<template>
  <v-col cols="12">
    <v-toolbar
      flat
      color="white"
    >
      <v-btn
        v-if="$vuetify.breakpoint.smAndUp"
        outlined
        rounded
        class="mr-3"
        color="grey darken-2"
        @click="setStart"
      >
        <v-icon left>
          {{ $vuetify.icons.values.start }}
        </v-icon>
        {{ $t('general.start') }}
      </v-btn>
      <v-btn
        v-if="$vuetify.breakpoint.smAndUp"
        outlined
        rounded
        class="mr-3"
        color="grey darken-2"
        @click="setEnd"
      >
        <v-icon left>
          {{ $vuetify.icons.values.end }}
        </v-icon>
        {{ $t('general.end') }}
      </v-btn>
      <v-btn
        icon
        @click="prev"
      >
        <v-icon>
          {{ $vuetify.icons.values.prev }}
        </v-icon>
      </v-btn>
      <v-btn
        icon
        @click="next"
      >
        <v-icon>
          {{ $vuetify.icons.values.next }}
        </v-icon>
      </v-btn>
      <v-toolbar-title
        class="mx-3"
        align="center"
      >
        {{ title }}
      </v-toolbar-title>
      <v-spacer />
      <v-menu
        bottom
        right
      >
        <template v-slot:activator="{ on }">
          <v-btn
            outlined
            rounded
            color="grey darken-2"
            v-on="on"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp">
              {{ $vuetify.icons.values.calendar }}
            </v-icon>
            <span v-if="$vuetify.breakpoint.smAndUp">
              {{ $t('general.' + type) }}
            </span>
            <v-icon
              v-if="$vuetify.breakpoint.smAndUp"
              right
            >
              {{ $vuetify.icons.values.expand }}
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="cType of calendarTypes"
            :key="cType"
            @click="type = cType"
          >
            <v-list-item-title>{{ $t('general.' + cType) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-sheet height="550">
      <v-calendar
        ref="calendar"
        v-model="focus"
        color="accent"
        :locale="locale"
        :events="events"
        :event-color="getEventColor"
        :type="type"
        :short-intervals="false"
        :short-months="false"
        :interval-height="24"
        :weekdays="[1, 2, 3, 4, 5, 6, 0]"
        @click:date="viewDay"
        @change="setDates"
      />
    </v-sheet>
  </v-col>
</template>

<script>
import { calendars } from '@/mixins/calendars'
import { DateTime } from 'luxon'

export default {
  name: 'PeriodPreviewCalendar',
  mixins: [calendars],
  props: {
    detailData: { type: Object, default: () => { return {} } },
    getPeriod: { type: Object, default: () => { return {} } },
    nextExecution: { type: Object, default: () => { return DateTime.local() } }
  },
  data () {
    return {
      focus: DateTime.fromISO(this.detailData.nextExecutionAt, { zone: this.detailData.timezone }).toISODate()
    }
  },
  computed: {
    events () {
      return [
        {
          name: this.detailData.title,
          start: this.getPeriod.start.toFormat('yyyy-LL-dd HH:mm'),
          end: this.getPeriod.end.toFormat('yyyy-LL-dd HH:mm'),
          color: 'primary'
        },
        {
          name: this.$t('schedules.nextExecutionAt'),
          start: this.nextExecution.toFormat('yyyy-LL-dd HH:mm'),
          color: 'success'
        }
      ]
    }
  }
}
</script>
