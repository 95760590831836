<script>
import { VSelect } from 'vuetify/lib'

export default {
  name: 'CustomSelect',
  extends: VSelect,
  props: {
    outlined: { type: Boolean, default: true }
  }
}
</script>
