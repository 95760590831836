import { RRule } from 'rrule'
import { DateTime, LocalZone } from 'luxon'

const startsAt = DateTime
  .local()
  .startOf('day')
  .plus({ days: 1 })
  .toFormat("yyyy-MM-dd'T'HH:mm")

const weekly = {
  form: {
    startsAt: startsAt,
    timezone: new LocalZone().name,
    format: 'csv',
    active: true
  },
  period: {
    category: 'days',
    days: 7,
    months: 0
  },
  rrule: {
    freq: RRule.WEEKLY,
    interval: 1,
    byweekday: [RRule.MO],
    bymonthday: null
  }
}

const monthly = {
  form: {
    startsAt: startsAt,
    timezone: new LocalZone().name,
    format: 'csv',
    active: true
  },
  period: {
    category: 'months',
    days: 0,
    months: 1
  },
  rrule: {
    freq: RRule.MONTHLY,
    interval: 1,
    byweekday: [],
    bymonthday: 1
  }
}

export const presets = {
  data () {
    return {
      presets: {
        weekly: weekly,
        monthly: monthly
      }
    }
  },
  methods: {
    applyPreset (category) {
      const preset = this.presets[category]
      if (!preset) return
      this.form = {
        title: this.form.title,
        ...preset.form
      }
      this.period = { ...preset.period }
      this.rrule = { ...preset.rrule }
    }
  }
}
