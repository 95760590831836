<template>
  <v-dialog
    ref="timezone"
    v-model="dialogState"
    overflow
    transition="slide-y-reverse-transition"
    max-width="500px"
    :return-value.sync="localZoneValue"
  >
    <template v-slot:activator="{ on: dialog }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            class="mt-2"
            text
            rounded
            v-on="{ ...tooltip, ...dialog }"
          >
            {{ $t('general.timeZone') }}
          </v-btn>
        </template>
        <span>{{ $t('general.customTimezoneTitle') }}</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">
          {{ $t('general.selectTimeZone') }}
        </span>
      </v-card-title>
      <v-card-text>
        <custom-autocomplete
          v-model="localZoneValue"
          :items="timezones"
          :hint="$t('general.timezoneSelectorHint') + ' ' + systemTimeZone"
          persistent-hint
        />
      </v-card-text>
      <v-card-text v-show="localZoneValue !== systemTimeZone">
        <alert
          type="warning"
          :text="$t('general.customTimezone', {
            local: systemTimeZone,
            selected: localZoneValue
          })"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          rounded
          @click="dialogState = false"
        >
          {{ $t('general.close') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          rounded
          @click="$refs.timezone.save(localZoneValue)"
        >
          {{ $t('general.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { LocalZone } from 'luxon'
import { timezones } from './time_zones'
import Alert from '@/components/alerts/Alert'
import CustomAutocomplete from '@/components/inputs/CustomAutocomplete'

export default {
  name: 'TimeZoneSelector',
  components: {
    Alert,
    CustomAutocomplete
  },
  props: {
    timezone: { type: String, default: '' }
  },
  data () {
    return {
      dialogState: false,
      timezones: timezones,
      systemTimeZone: new LocalZone().name
    }
  },
  computed: {
    localZoneValue: {
      get () {
        return this.timezone
      },
      set (timezone) {
        this.$emit('update:timezone', timezone)
      }
    }
  }
}
</script>
