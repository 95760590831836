<template>
  <v-row v-if="displayRRule">
    <v-col cols="12">
      <title-and-content-row :title="$t('general.details')">
        <template v-slot:content>
          <div>
            <strong>{{ detailData.title }}</strong> ( {{ detailData.format }} )
            <div class="text-caption grey--text text--darken-2">
              {{ $t('schedules.createdAt') }}:
              {{ getDateTimeValue(detailData.createdAt, 'long') }}
            </div>
            <div class="text-caption grey--text text--darken-2">
              {{ $t('schedules.updatedAt') }}:
              {{ getDateTimeValue(detailData.updatedAt, 'long') }}
            </div>
            <div class="text-caption grey--text text--darken-2">
              {{ $t('schedules.lastExecutedAt') }}:
              {{ getDateTimeValue(detailData.lastExecutedAt, 'long') }}
            </div>
          </div>
        </template>
      </title-and-content-row>
      <v-divider />
      <title-and-content-row :title="$t('schedules.schedule')">
        <template v-slot:content>
          <div>
            <div>{{ rruleText }},</div>
            <div v-if="detailData.periodDays">
              {{ $tc('schedules.periodPreviewDays', detailData.periodDays, { period: detailData.periodDays }) }}.
            </div>
            <div v-if="detailData.periodMonths">
              {{ $tc('schedules.periodPreviewMonths', detailData.periodMonths, { period: detailData.periodDays }) }}.
            </div>
            <div
              class="text-caption grey--text text--darken-2"
            >
              {{ getZonedDateTime(getPeriod.start, 'long', detailData.timezone) }}
              -
              {{ getZonedDateTime(getPeriod.end, 'long', detailData.timezone) }}
            </div>
            <div
              class="text-caption grey--text text--darken-2"
            >
              {{ getTimeZone(detailData.nextExecutionAt, detailData.timezone) }}
            </div>
          </div>
        </template>
      </title-and-content-row>
      <v-divider />
      <title-and-content-row :title="$t('schedules.nextExecutionAt')">
        <template v-slot:content>
          <div>
            <div>
              {{ getZonedDateTime(detailData.nextExecutionAt, 'long', detailData.timezone) }}
            </div>
            <div
              class="text-caption grey--text text--darken-2"
            >
              {{ getTimeZone(detailData.nextExecutionAt, detailData.timezone) }}
            </div>
          </div>
        </template>
        <template v-slot:action>
          <v-btn
            outlined
            small
            rounded
            :input-value="nextExecutionPreview"
            active-class="primary--text"
            @click="nextExecutionPreview = !nextExecutionPreview"
          >
            {{ $t('general.preview') }}
          </v-btn>
        </template>
      </title-and-content-row>
    </v-col>
    <simple-side-sheet
      :expand-sheet.sync="nextExecutionPreview"
    >
      <template v-slot:title>
        {{ $t('general.preview') }}:
        {{ $t('schedules.nextExecutionAt') }}
      </template>
      <template v-slot:content>
        <period-preview-calendar
          :detail-data="detailData"
          :get-period="getPeriod"
          :next-execution="nextExecution"
        />
      </template>
    </simple-side-sheet>
  </v-row>
</template>

<script>
import { getDateTimeValue, getZonedDateTime } from '@/mixins/dates'
import { DateTime } from 'luxon'
import { format } from 'date-fns'
import { RRule } from 'rrule'
import PeriodPreviewCalendar from '@/components/details/schedules/PeriodPreviewCalendar'
import TitleAndContentRow from '@/components/layout/TitleAndContentRow'
import SimpleSideSheet from '@/components/side_sheets/SimpleSideSheet'
import { enUS, bg } from 'date-fns/locale'

const ordinalLocales = {
  en: enUS,
  bg: bg
}

export default {
  name: 'ReportScheduleDetailSchedule',
  components: {
    PeriodPreviewCalendar,
    TitleAndContentRow,
    SimpleSideSheet
  },
  mixins: [getDateTimeValue, getZonedDateTime],
  props: {
    detailData: { type: Object, default: () => { return {} } },
    loadingData: { type: Boolean, default: false }
  },
  data () {
    return {
      nextExecutionPreview: false
    }
  },
  computed: {
    locale () {
      return this.$i18n.locale
    },
    nextExecution () {
      return DateTime
        .fromISO(this.detailData.nextExecutionAt, { zone: this.detailData.timezone })
        .setLocale(this.locale)
    },
    getPeriod () {
      const end = this.nextExecution
      const days = this.$getNumber(this.detailData.periodDays)
      const months = this.$getNumber(this.detailData.periodMonths)
      const start = end.minus({ days: days, months: months })
      return {
        start: start,
        end: end
      }
    },
    displayRRule () {
      return this.detailData.rrule
    },
    parsedRRule () {
      return this.displayRRule
        ? RRule.parseString(this.detailData.rrule)
        : {}
    },
    rruleText () {
      const frequency = RRule.FREQUENCIES[this.parsedRRule.freq].toLowerCase()
      const interval = this.parsedRRule.interval
      const monthDay = new Date().setDate(this.parsedRRule.bymonthday || 1)
      const weekdays = this.parsedRRule.byweekday
        ? this.parsedRRule.byweekday.map(day => this.$t(`weekdays.${day.toString().toLowerCase()}`))
        : ''
      const ordinalMonthDay = format(monthDay, 'do', {
        locale: ordinalLocales[this.locale]
      })

      return this.$tc(`schedules.toText.${frequency}`, interval, {
        interval: interval,
        weekdays: weekdays,
        startsAt: this.nextExecution.toFormat('HH:mm'),
        ordinalMonthDay: ordinalMonthDay
      })
    }
  }
}
</script>
