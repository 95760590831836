<template>
  <create-modal-layout
    :loading="loading"
    :success="success"
    :error="error"
    :mode="mode"
    :form-valid="formValid"
    :dialog-state.sync="dialogState"
    i18n-context="schedules"
    @form:submit="submitForm"
  >
    <template v-slot:form>
      <v-form
        v-show="!success && !error"
        ref="formSchedulesCreate"
        v-model="formValid"
        lazy-validation
      >
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              md="6"
              lg="4"
            >
              <v-row>
                <v-col cols="12">
                  <custom-text-field
                    v-model="form.title"
                    :rules="[rules.required]"
                    :label="$t('schedules.create.title') + ' *'"
                    :hint="$t('schedules.create.titleHint')"
                    persistent-hint
                    clearable
                    :autofocus="dialogState"
                    counter="255"
                    maxlength="255"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                >
                  <date-time-picker
                    :date-picker-props="startsAtProps"
                    :date-time-string.sync="form.startsAt"
                    :rules="[rules.required]"
                    :show-current="false"
                    :time-zone="form.timezone"
                    :zone-offset="zoneOffset"
                    @close="startsAtTimeProps.menu = false"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <time-zone-selector :timezone.sync="form.timezone" />
                </v-col>
                <v-col
                  cols="12"
                  sm="8"
                >
                  <custom-select
                    v-model="category"
                    :label="$t('schedules.create.repeats') + ' *'"
                    :hint="$t('schedules.create.recurrenceHint')"
                    :items="presetsOptions"
                    persistent-hint
                    @input="applyPreset"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                >
                  <custom-select
                    v-model="form.format"
                    :label="$t('schedules.create.format') + ' *'"
                    :hint="$t('schedules.create.formatHint')"
                    :items="['csv']"
                    persistent-hint
                    disabled
                  />
                </v-col>
                <v-col
                  v-show="isCustom"
                  cols="12"
                  class="py-0"
                >
                  <v-checkbox
                    v-model="form.active"
                    :label="$t('schedules.create.active')"
                  />
                </v-col>
                <v-col
                  v-show="isCustom"
                  cols="12"
                  class="text-body-2 text-uppercase blue-grey--text text--darken-3 py-4"
                >
                  {{ $t('schedules.create.period') }}
                </v-col>
                <v-col
                  v-show="isCustom"
                  cols="12"
                  sm="6"
                >
                  <custom-select
                    v-model="period.category"
                    :label="$t('schedules.create.unit') + ' *'"
                    :hint="$t('schedules.create.unitHint')"
                    :items="periodOptions"
                    persistent-hint
                    @input="cleanUpPeriod"
                  />
                </v-col>
                <v-col
                  v-if="period.category === 'days'"
                  v-show="isCustom"
                  cols="12"
                  sm="6"
                >
                  <custom-text-field
                    v-model.number="period.days"
                    v-integers-only
                    :rules="[rules.required, rules.minimumPeriodDays, rules.maximumPeriodDays]"
                    :label="$tc('schedules.create.day', period.days) + ' *'"
                    :hint="$t('schedules.create.periodHint')"
                    :placeholder="$t('general.integer')"
                    persistent-hint
                    clearable
                  />
                </v-col>
                <v-col
                  v-if="period.category === 'months'"
                  v-show="isCustom"
                  cols="12"
                  sm="6"
                >
                  <custom-text-field
                    v-model.number="period.months"
                    v-integers-only
                    :rules="[rules.required, rules.minimumPeriodMonths, rules.maximumPeriodMonths]"
                    :label="$tc('schedules.create.month', period.months) + ' *'"
                    :hint="$t('schedules.create.periodHint')"
                    :placeholder="$t('general.integer')"
                    persistent-hint
                    clearable
                  />
                </v-col>
                <v-col
                  v-show="isCustom"
                  cols="12"
                  class="text-body-2 text-uppercase blue-grey--text text--darken-3 py-4"
                >
                  {{ $t('schedules.create.recurrence') }}
                </v-col>
                <v-col
                  v-show="isCustom"
                  cols="12"
                  sm="6"
                >
                  <custom-text-field
                    v-model.number="rrule.interval"
                    v-integers-only
                    :rules="[rules.required, rules.minimumInterval]"
                    :label="$t('schedules.create.interval') + ' *'"
                    :hint="$t('schedules.create.intervalHint')"
                    :prefix="$t('schedules.create.repeatsEvery')"
                    persistent-hint
                    clearable
                  />
                </v-col>
                <v-col
                  v-show="isCustom"
                  cols="12"
                  sm="6"
                >
                  <custom-select
                    v-model="rrule.freq"
                    :rules="[rules.required]"
                    :label="$t('schedules.create.frequency') + ' *'"
                    :hint="$t('schedules.create.frequencyHint')"
                    :items="frequencyOptions"
                    persistent-hint
                    @input="cleanUpFrequencies"
                  />
                </v-col>
                <v-col
                  v-show="isCustom"
                  v-if="showWeekDaysChips"
                  cols="12"
                >
                  <span class="text-caption">{{ $t('schedules.create.byWeekDay') }}</span>
                  <v-chip-group
                    v-model="rrule.byweekday[0]"
                    mandatory
                    column
                    color="primary"
                  >
                    <v-chip
                      v-for="(day, index) in weekDaysOptions"
                      :key="index + day.text"
                      dense
                      :value="day.value"
                    >
                      {{ day.text }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
                <v-col
                  v-show="isCustom"
                  v-if="showMonthDaysInput"
                  cols="12"
                  sm="6"
                >
                  <custom-text-field
                    v-model.number="rrule.bymonthday"
                    v-integers-only
                    :rules="[rules.required]"
                    :label="$t('schedules.create.byMonthDay') + ' *'"
                    :hint="$t('schedules.create.byMonthDayHint')"
                    :placeholder="$t('general.integer')"
                    persistent-hint
                    clearable
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              lg="auto"
            >
              <v-divider
                class="mx-4"
                :vertical="$vuetify.breakpoint.lgAndUp"
              />
            </v-col>
            <v-col>
              <schedule-preview-calendar
                :get-period="getPeriod"
                :ocurrences="getRRuleOcurrencesPreview"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </template>
  </create-modal-layout>
</template>

<script>
import api from '@/api/modules/schedules'
import { required } from '@/mixins/validation'
import { presets } from './presets'
import {
  dialogTriggers,
  dialogData
} from '@/mixins/dialogs'
import { RRule } from 'rrule'
import { DateTime, LocalZone, IANAZone } from 'luxon'
import DateTimePicker from '@/components/create/inputs/pickers/DateTime'
import TimeZoneSelector from './TimeZoneSelector'
import CreateModalLayout from '@/components/modals/CreateLayout'
import SchedulePreviewCalendar from '@/components/modals/schedules/calendar/SchedulePreviewCalendar'
import CustomTextField from '@/components/inputs/CustomTextField'
import CustomSelect from '@/components/inputs/CustomSelect'

export default {
  name: 'ScheduleCreateDialog',
  components: {
    DateTimePicker,
    TimeZoneSelector,
    CreateModalLayout,
    SchedulePreviewCalendar,
    CustomTextField,
    CustomSelect
  },
  mixins: [
    required,
    dialogTriggers,
    dialogData,
    presets
  ],
  props: {
    dialogData: { type: Object, default: () => { return {} } }
  },
  data () {
    return {
      mode: 'create',
      category: 'weekly',
      form: {
        title: null,
        startsAt: DateTime.local().toISO(),
        timezone: new LocalZone().name,
        format: 'csv',
        active: true
      },
      period: {
        category: 'days',
        days: 0,
        months: 0
      },
      rrule: {
        freq: RRule.WEEKLY,
        interval: 1,
        byweekday: [RRule.MO],
        bymonthday: null
      },
      startsAtProps: {
        menu: false,
        label: 'schedules.create.startsAt',
        hint: 'schedules.create.startsAtHint'
      },
      rules: {
        minimumInterval: value => value >= 1 || this.$t('schedules.create.minimumInterval'),
        minimumPeriodDays: value => value >= 7 || this.$t('schedules.create.minimumPeriod'),
        maximumPeriodDays: value => value <= 365 || this.$t('schedules.create.maximumPeriod'),
        minimumPeriodMonths: value => value >= 1 || this.$t('schedules.create.minimumPeriod'),
        maximumPeriodMonths: value => value <= 12 || this.$t('schedules.create.maximumPeriod')
      }
    }
  },
  computed: {
    locale () {
      return this.$i18n.locale
    },
    isCustom () {
      return this.category === 'custom'
    },
    getRRule () {
      const rrule = new RRule({
        freq: this.rrule.freq,
        interval: this.$getNumber(this.rrule.interval),
        byweekday: this.rrule.byweekday,
        bymonthday: this.rrule.bymonthday
      })
      return rrule
    },
    getRRuleOcurrencesPreview () {
      const dtstart = DateTime
        .fromISO(this.form.startsAt, { zone: 'utc' })
        .toJSDate()
      const rrule = new RRule({
        dtstart: dtstart,
        freq: this.rrule.freq,
        interval: this.$getNumber(this.rrule.interval),
        byweekday: this.rrule.byweekday,
        bymonthday: this.rrule.bymonthday,
        count: 3
      })
      const rulesArray = rrule.all().map((d, index) => {
        return DateTime
          .fromJSDate(d)
          .toUTC()
          .setZone(this.form.timezone, { keepLocalTime: true })
      })
      return rulesArray
    },
    zoneOffset () {
      return IANAZone.create(this.form.timezone).formatOffset(DateTime.local().toMillis(), 'short')
    },
    getPeriod () {
      const end = DateTime.fromISO(this.getRRuleOcurrencesPreview[0], { zone: this.form.timezone })
      const days = this.$getNumber(this.period.days)
      const months = this.$getNumber(this.period.months)
      const start = end.minus({ days: days, months: months })
      return {
        start: start,
        end: end
      }
    },
    presetsOptions () {
      return [
        { text: this.$t('schedules.create.presets.weekly'), value: 'weekly' },
        { text: this.$t('schedules.create.presets.monthly'), value: 'monthly' },
        { text: this.$t('schedules.create.presets.custom'), value: 'custom' }
      ]
    },
    periodOptions () {
      return [
        { text: this.$tc('schedules.create.day', 1), value: 'days' },
        { text: this.$tc('schedules.create.month', 1), value: 'months' }
      ]
    },
    weekDaysOptions () {
      return [
        { text: this.$t('weekdays.mo'), value: RRule.MO },
        { text: this.$t('weekdays.tu'), value: RRule.TU },
        { text: this.$t('weekdays.we'), value: RRule.WE },
        { text: this.$t('weekdays.th'), value: RRule.TH },
        { text: this.$t('weekdays.fr'), value: RRule.FR },
        { text: this.$t('weekdays.sa'), value: RRule.SA },
        { text: this.$t('weekdays.su'), value: RRule.SU }
      ]
    },
    frequencyOptions () {
      const plural = this.rrule.interval > 1 ? 2 : 1
      return [
        { text: this.$tc('schedules.create.week', plural), value: RRule.WEEKLY },
        { text: this.$tc('schedules.create.month', plural), value: RRule.MONTHLY }
      ]
    },
    showWeekDaysChips () {
      return this.rrule.freq === RRule.WEEKLY
    },
    showMonthDaysInput () {
      return this.rrule.freq === RRule.MONTHLY
    },
    showFormValidityAlert () {
      return this.formValid === false &&
      (!this.startsAtDateProps.menu && !this.untilDateProps.menu)
    }
  },
  methods: {
    openInCreateMode () {
      this.resetState()
      this.dialogState = true
    },
    openInEditMode () {
      this.resetState()
      const data = { ...this.dialogData }
      this.mode = 'edit'
      this.category = 'custom'
      this.form = {
        title: data.title,
        startsAt: DateTime.fromISO(data.startsAt).setZone(data.timezone).toFormat("yyyy-LL-dd'T'HH:mm"),
        timezone: data.timezone,
        format: 'csv',
        active: data.active
      }
      this.period = {
        category: data.periodDays > 0 ? 'days' : 'months',
        days: data.periodDays,
        months: data.periodMonths
      }
      this.rrule = {
        ...this.rrule,
        ...RRule.parseString(data.rrule)
      }
      this.dialogState = true
    },
    cleanUpPeriod () {
      this.period.days = 0
      this.period.months = 0
    },
    cleanUpFrequencies () {
      this.rrule.byweekday = []
      this.rrule.bymonthday = null
    },
    resetState () {
      this.success = false
      this.error = false
      this.formValid = null
      this.submitDisabled = false
      if (this.$refs.formSchedulesCreate) {
        this.$refs.formSchedulesCreate.resetValidation()
      }
      this.resetFormState()
    },
    resetFormState () {
      this.form.title = null
      this.category = 'weekly'
      this.applyPreset('weekly')
    },
    async submitForm () {
      if (this.$refs.formSchedulesCreate.validate()) {
        this.loading = true

        try {
          const form = {
            ...this.form,
            rrule: this.getRRule.toString(),
            periodDays: this.$getNumber(this.period.days),
            periodMonths: this.$getNumber(this.period.months)
          }
          const response = await api.submitData(form, this.mode, this.dialogData.id)
          this.loading = false
          if (response.status >= 200 && response.status < 300) {
            this.success = true
            this.$emit('refresh')
          } else {
            this.error = true
          }
        } catch (error) {
          this.loading = false
          this.error = true
        }
      }
    }
  }
}
</script>
