<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <details-toolbar
          :title="$t('schedules.detail.cardInfoTitle')"
          :detail-data="detailData"
          :permissions="toolbarPermissions"
          show-options
        >
          <template v-slot:chip>
            <v-chip
              :input-value="true"
              :color="status.color"
            >
              <v-icon left>
                {{ status.icon }}
              </v-icon>
              {{ status.text }}
            </v-chip>
          </template>
          <template v-slot:custom-action>
            <v-btn
              rounded
              outlined
              :disabled="!hasWorkspacePermission('change_users_reportschedule')"
              :loading="loadingStatus"
              color="primary"
              class="ml-2"
              @click="toggleActiveStatus"
            >
              {{ $t(statusButtonLabel) }}
            </v-btn>
          </template>
        </details-toolbar>
        <card-layout disable-title>
          <template v-slot:content>
            <report-schedule-detail-schedule
              :detail-data="detailData"
              :loading-data="loadingData"
            />
          </template>
        </card-layout>
      </v-col>
      <v-col cols="12">
        <details-toolbar
          :title="$t('schedules.reports')"
          :show-divider="false"
        />
        <card-layout disable-title>
          <template v-slot:content>
            <report-schedule-detail-reports />
          </template>
        </card-layout>
      </v-col>
      <delete-dialog
        i18n-context="schedules"
      />
      <schedule-create-dialog
        :dialog-data="detailData"
        @refresh="getDetailData"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/api/modules/schedules'
import { getDateTimeValue } from '@/mixins/dates'
import CardLayout from '@/components/layout/CardLayout'
import DetailsToolbar from '@/components/toolbars/DetailsToolbar'
import ReportScheduleDetailSchedule from '@/components/details/schedules/Schedule'
import ReportScheduleDetailReports from '@/components/details/schedules/Reports'
import ScheduleCreateDialog from '@/components/modals/schedules/Create'
import DeleteDialog from '@/components/modals/schedules/Delete'

export default {
  name: 'ReportSchedulesDetails',
  components: {
    CardLayout,
    DetailsToolbar,
    ReportScheduleDetailSchedule,
    ReportScheduleDetailReports,
    ScheduleCreateDialog,
    DeleteDialog
  },
  mixins: [getDateTimeValue],
  data () {
    return {
      activeTab: 'schHead',
      detailData: {},
      loadingData: false,
      loadingStatus: false,
      toolbarPermissions: {
        edit: 'change_users_reportschedule',
        delete: 'delete_users_reportschedule'
      }
    }
  },
  computed: {
    ...mapGetters([
      'hasWorkspacePermission'
    ]),
    status () {
      return this.detailData.active
        ? {
          color: 'success--text',
          icon: this.$vuetify.icons.values.check,
          text: this.$t('general.active')
        }
        : {
          color: 'grey--text',
          icon: this.$vuetify.icons.values.inactive,
          text: this.$t('general.inactive')
        }
    },
    statusButtonLabel () {
      return this.detailData.active
        ? 'general.deactivate'
        : 'general.activate'
    }
  },
  mounted () {
    this.getDetailData()
  },
  methods: {
    async toggleActiveStatus () {
      this.loadingStatus = true
      await api.toggleActiveStatus(!this.detailData.active)
      await this.getDetailData()
      this.loadingStatus = false
    },
    async getDetailData () {
      this.loadingData = true
      const result = await api.fetchDetail()
      this.detailData = { ...result }
      this.loadingData = false
    }
  }
}
</script>
