<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialogState"
      scrollable
      transition="slide-y-reverse-transition"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="red lighten-4">
          <span class="text-h5 red--text text--darken-4">
            <slot name="title" />
            {{ $t(i18nContext + '.delete.title') }}
          </span>
        </v-card-title>
        <v-card-text class="py-3">
          <v-row>
            <v-col
              v-show="!success && !error"
              cols="12"
            >
              <p>
                <span class="text-h6">
                  {{ dialogData.title }}
                </span>
              </p>
              <span>{{ $t(i18nContext + '.delete.body') }}</span>
            </v-col>
          </v-row>
          <full-screen-alert
            :show-alert="success"
            type="success"
            :text="$t('documents.delete.success')"
          />
          <full-screen-alert
            :show-alert="error"
            type="error"
            :text="$t('documents.delete.error')"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-show="!success"
            color="primary"
            text
            rounded
            @click.native="dialogState = false"
          >
            {{ $t('general.close') }}
          </v-btn>
          <v-btn
            v-show="!success && !error"
            color="primary"
            text
            rounded
            :loading="loading"
            @click.native="deleteSchedule(dialogData.id)"
          >
            {{ $t('general.delete') }}
          </v-btn>
          <v-slide-x-transition>
            <v-btn
              v-show="success"
              text
              rounded
              color="primary"
              @click.native="dialogState = false"
            >
              {{ $t('general.close') }}
            </v-btn>
          </v-slide-x-transition>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import api from '@/api/modules/schedules'
import FullScreenAlert from '@/components/alerts/FullScreenAlert'

export default {
  name: 'DeleteDialog',
  components: {
    FullScreenAlert
  },
  props: {
    i18nContext: { type: String, default: '' }
  },
  data () {
    return {
      dialogState: false,
      dialogData: {},
      loading: false,
      success: false,
      error: false
    }
  },
  watch: {
    success: function (success) {
      if (success) {
        setTimeout(function () {
          this.$router.replace({ name: 'ReportSchedulesList' })
        }.bind(this), 700)
      }
    }
  },
  created () {
    this.$bus.on('open:delete-dialog', this.openDialog)
  },
  beforeDestroy () {
    this.$bus.off('open:delete-dialog', this.openDialog)
  },
  methods: {
    openDialog (dialogData) {
      this.resetState()
      this.dialogData = { ...dialogData }
      this.dialogState = true
    },
    resetState () {
      this.loading = false
      this.success = false
      this.error = false
    },
    async deleteSchedule (id) {
      if (id) {
        this.loading = true
        try {
          const response = await api.deleteSchedule(id)
          this.loading = false
          if (response.status >= 200 && response.status < 300) {
            this.success = true
            this.$emit('refresh')
          } else {
            this.error = true
          }
        } catch (error) {
          this.loading = false
          this.error = true
        }
      }
    }
  }
}
</script>
