import { apiHttpService } from '@/api'
import { getWorkspaceId, getRouteDetailId } from '@/api/utils/route_params'
import { arraySerializer } from '@/api/utils/serializer'
import { setPaginationParams } from '@/api/utils/pagination_params'
import {
  getISOStringDateTimeGte,
  getISOStringDateTimeLte
} from '@/api/utils/iso_date'
import { DateTime } from 'luxon'
import { RRule } from 'rrule'

const parseRRule = rruleString => RRule.parseString(rruleString)

const getFrequency = rruleString => {
  try {
    const rrule = parseRRule(rruleString)
    const frequency = RRule.FREQUENCIES[rrule.freq] || ''
    return frequency.toLowerCase()
  } catch (_) {
    return ''
  }
}

const mapResponse = response => {
  return {
    id: response.id,
    title: response.title,
    format: response.report_format,
    timezone: response.timezone,
    startsAt: response.starts_at,
    frequency: getFrequency(response.rrule),
    rrule: response.rrule,
    active: response.active,
    status: response.active ? 'active' : 'inactive',
    periodMonths: response.period_months,
    periodDays: response.period_days,
    createdAt: response.created_at,
    lastExecutedAt: response.last_executed_at,
    nextExecutionAt: response.next_execution_at,
    updatedAt: response.updated_at
  }
}

const processResponseList = list => list.map(item => {
  return mapResponse(item)
})

export default {
  async fetchDetail () {
    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/report-schedules/' + getRouteDetailId(),
        method: 'GET',
        transformResponse: [data => {
          return mapResponse(data)
        }]
      })
      return Promise.resolve(response.data)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async fetchList (queryParams) {
    let params = {}
    if (queryParams.activeFilters) {
      params = {
        created_at_gte: getISOStringDateTimeGte(queryParams.activeFilters.createdDateFrom),
        created_at_lte: getISOStringDateTimeLte(queryParams.activeFilters.createdDateTo),
        active: queryParams.activeFilters.activeStatus
      }
    }

    setPaginationParams(queryParams, params, 'reports')

    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/report-schedules/',
        method: 'GET',
        params: arraySerializer(params)
      })
      const processedResponse = {
        itemCount: response.data.count,
        pageCount: response.data.total_pages,
        items: processResponseList(response.data.results)
      }
      return Promise.resolve(processedResponse)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async submitData (form, mode = 'create', id = undefined) {
    const method = mode === 'create' ? 'POST' : 'PATCH'
    const scheduleId = id || ''
    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/report-schedules/' + scheduleId,
        method: method,
        data: {
          title: form.title,
          report_format: form.format,
          starts_at: DateTime.fromISO(form.startsAt, { zone: form.timezone }).toISO(),
          timezone: form.timezone,
          rrule: form.rrule,
          active: form.active,
          period_months: form.periodMonths,
          period_days: form.periodDays
        }
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async deleteSchedule () {
    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/report-schedules/' + getRouteDetailId(),
        method: 'DELETE'
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async toggleActiveStatus (status) {
    try {
      const response = await apiHttpService({
        url: '/workspaces/' + getWorkspaceId() + '/report-schedules/' + getRouteDetailId(),
        method: 'PATCH',
        data: {
          active: status
        }
      })
      return Promise.resolve(response)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}
