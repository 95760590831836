import { DateTime, Interval } from 'luxon'

export const calendars = {
  data () {
    return {
      focus: null,
      type: 'month',
      start: '',
      end: '',
      calendarTypes: [
        'day',
        'week',
        'month'
      ]
    }
  },
  computed: {
    locale () {
      return this.$i18n.locale
    },
    startDate () {
      return DateTime
        .local()
        .set({
          day: this.start.day,
          wee: this.start.week,
          month: this.start.month,
          year: this.start.year
        })
        .setLocale(this.locale)
    },
    endDate () {
      return DateTime
        .local()
        .set({
          day: this.end.day,
          wee: this.end.week,
          month: this.end.month,
          year: this.end.year
        })
        .setLocale(this.locale)
    },
    title () {
      const calTypeMap = {
        day: this.startDate
          .toFormat('LLLL dd, yyyy'),
        week: `${Interval
          .fromDateTimes(this.startDate, this.endDate)
          .toFormat('LLLL dd')}, ${this.startDate.year}`,
        month: this.startDate
          .toFormat('LLLL yyyy')
      }
      return calTypeMap[this.type]
    }
  },
  methods: {
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    setStart () {
      this.focus = this.getPeriod.start.toISODate()
    },
    setEnd () {
      this.focus = this.getPeriod.end.toISODate()
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    getEventColor (event) {
      return event.color
    },
    setDates ({ start, end }) {
      this.start = start
      this.end = end
    }
  }
}
