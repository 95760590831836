<template>
  <v-row>
    <v-col cols="12">
      <table-toolbar
        :context="tableToolbarContext"
        :loading-data="loadingData"
        :expand-table-filters.sync="expandTableFilters"
        :filters-are-active="filtersAreActive"
        @refreshData="getTableData"
      />
      <v-data-table
        :loading="loadingData"
        :headers="headers"
        :items="tableData.items"
        :options.sync="paginationOptions"
        :server-items-length="tableData.itemCount"
        :style="tableWrapStyle"
        :footer-props="{
          itemsPerPageOptions: paginationOptions.itemsRange,
          showCurrentPage: true,
          showFirstLastPage: true,
          disablePagination: loadingData,
          disableItemsPerPage: loadingData
        }"
      >
        <template v-slot:item.treatment="{ item }">
          <v-icon
            v-if="item.completedAt"
            color="success"
          >
            {{ $vuetify.icons.values.check }}
          </v-icon>
          <v-icon
            v-else
            color="grey"
          >
            {{ $vuetify.icons.values.loading }}
          </v-icon>
        </template>
        <template v-slot:item.schedule="{ item }">
          {{ getScheduleString(item.schedule) }}
        </template>
        <template v-slot:item.documentCreatedFrom="{ item }">
          {{ getDateTimeValue(item.documentCreatedFrom, 'long') }}
        </template>
        <template v-slot:item.documentCreatedTo="{ item }">
          {{ getDateTimeValue(item.documentCreatedTo, 'long') }}
        </template>
        <template v-slot:item.createdAt="{ item }">
          {{ getDateTimeValue(item.createdAt, 'long') }}
        </template>
        <template v-slot:item.completedAt="{ item }">
          {{ getDateTimeValue(item.completedAt, 'long') }}
        </template>
        <template v-slot:item.actions="{ item }">
          <table-button
            :link="{
              name: 'DocumentReportsDetails',
              params: {
                routeDetailId: item.id
              }
            }"
            text="general.details"
          />
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import api from '@/api/modules/reports'
import { getDateTimeValue } from '@/mixins/dates'
import { listViews } from '@/mixins/list_views'
import TableToolbar from '@/components/toolbars/TableToolbar'
import TableButton from '@/components/buttons/TableButton'

export default {
  name: 'ReportSchedulesReports',
  components: {
    TableToolbar,
    TableButton
  },
  mixins: [
    getDateTimeValue,
    listViews
  ],
  data () {
    return {
      tableToolbarContext: {
        enableFilters: false,
        enableTitle: true,
        title: ''
      },
      loadingData: false
    }
  },
  computed: {
    headers () {
      return [
        { text: this.$t('general.details'), sortable: false, value: 'actions', width: 48 },
        { text: this.$t('reports.documentCreatedFrom'), sortable: false, value: 'documentCreatedFrom' },
        { text: this.$t('reports.documentCreatedTo'), sortable: false, value: 'documentCreatedTo' },
        { text: this.$t('reports.status'), sortable: false, value: 'treatment', align: 'center' },
        { text: this.$t('reports.completedAt'), sortable: false, value: 'completedAt' },
        { text: this.$t('reports.createdAt'), sortable: false, value: 'createdAt' }
      ]
    }
  },
  methods: {
    async getTableData () {
      const params = {
        ...this.paginationOptions,
        activeFilters: {
          reportSchedule: this.$route.params.routeDetailId
        }
      }
      this.loadingData = true
      const result = await api.fetchList(params)
      this.tableData = { ...result }
      this.loadingData = false
    }
  }
}
</script>
