var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{ref:"timezone",attrs:{"overflow":"","transition":"slide-y-reverse-transition","max-width":"500px","return-value":_vm.localZoneValue},on:{"update:returnValue":function($event){_vm.localZoneValue=$event},"update:return-value":function($event){_vm.localZoneValue=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2",attrs:{"text":"","rounded":""}},Object.assign({}, tooltip, dialog)),[_vm._v(" "+_vm._s(_vm.$t('general.timeZone'))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('general.customTimezoneTitle')))])])]}}]),model:{value:(_vm.dialogState),callback:function ($$v) {_vm.dialogState=$$v},expression:"dialogState"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('general.selectTimeZone'))+" ")])]),_c('v-card-text',[_c('custom-autocomplete',{attrs:{"items":_vm.timezones,"hint":_vm.$t('general.timezoneSelectorHint') + ' ' + _vm.systemTimeZone,"persistent-hint":""},model:{value:(_vm.localZoneValue),callback:function ($$v) {_vm.localZoneValue=$$v},expression:"localZoneValue"}})],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(_vm.localZoneValue !== _vm.systemTimeZone),expression:"localZoneValue !== systemTimeZone"}]},[_c('alert',{attrs:{"type":"warning","text":_vm.$t('general.customTimezone', {
          local: _vm.systemTimeZone,
          selected: _vm.localZoneValue
        })}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","rounded":""},on:{"click":function($event){_vm.dialogState = false}}},[_vm._v(" "+_vm._s(_vm.$t('general.close'))+" ")]),_c('v-btn',{attrs:{"color":"primary","text":"","rounded":""},on:{"click":function($event){return _vm.$refs.timezone.save(_vm.localZoneValue)}}},[_vm._v(" "+_vm._s(_vm.$t('general.ok'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }