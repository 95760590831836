<template>
  <v-menu
    ref="dateTime"
    v-model="menuState"
    :close-on-content-click="false"
    offset-y
    :nudge-right="40"
    min-width="290px"
    transition="slide-x-transition"
    :return-value.sync="localDateTime"
    @input="resetLocalState"
  >
    <template v-slot:activator="{ on: menu }">
      <custom-text-field
        :value="formattedDateTime"
        :autofocus="autofocus"
        :label="$t(datePickerProps.label) + ' *'"
        :rules="rules"
        :hint="tzHint"
        :disabled="disabled"
        persistent-hint
        readonly
        v-on="{ ...menu }"
      >
        <template v-slot:append>
          <v-tooltip
            top
          >
            <template v-slot:activator="{ on: tooltip }">
              <v-icon
                v-show="customTimezone"
                color="warning"
                v-on="{ ...tooltip }"
              >
                {{ $vuetify.icons.values.warning }}
              </v-icon>
            </template>
            <span>
              {{ $t('general.customTimezone', {
                selected: timeZone,
                local: localTimeZone
              }) }}
            </span>
          </v-tooltip>
        </template>
      </custom-text-field>
    </template>
    <v-card flat>
      <v-tabs
        icons-and-text
        hide-slider
      >
        <v-tab key="calendar">
          {{ $t('general.date') }}
          <v-icon>
            {{ $vuetify.icons.values.dateRange }}
          </v-icon>
        </v-tab>
        <v-tab
          key="timer"
        >
          {{ $t('general.time') }}
          <v-icon>
            {{ $vuetify.icons.values.time }}
          </v-icon>
        </v-tab>
        <v-tab-item
          key="calendar"
        >
          <v-date-picker
            v-model="date"
            class="elevation-0"
            first-day-of-week="1"
            scrollable
            :min="minDate"
            :max="maxDate"
            :landscape="$vuetify.breakpoint.mdAndUp"
            :locale="$i18n.locale"
            :show-current="showCurrent"
            @input="updateLocalDateTime"
          />
        </v-tab-item>
        <v-tab-item key="timer">
          <v-time-picker
            v-model="time"
            class="elevation-0"
            format="24hr"
            scrollable
            :landscape="$vuetify.breakpoint.mdAndUp"
            @input="updateLocalDateTime"
          />
        </v-tab-item>
      </v-tabs>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          rounded
          color="primary"
          @click="menuState = false"
        >
          {{ $t('general.close') }}
        </v-btn>
        <v-btn
          text
          rounded
          color="primary"
          @click="$refs.dateTime.save(localDateTime)"
        >
          {{ $t('general.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { DateTime, LocalZone } from 'luxon'
import CustomTextField from '@/components/inputs/CustomTextField'

export default {
  name: 'DateTimePicker',
  components: {
    CustomTextField
  },
  props: {
    autofocus: { type: Boolean, default: false },
    datePickerProps: { type: Object, default: () => { return {} } },
    dateTimeString: { type: String, default: '' },
    hint: { type: String, default: '' },
    minDate: { type: String, default: '' },
    maxDate: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    rules: { type: Array, default: () => { return [] } },
    showCurrent: { type: Boolean, default: true },
    timeZone: { type: String, default: new LocalZone().name },
    zoneOffset: { type: String, default: '' }
  },
  data () {
    return {
      menuState: false,
      date: null,
      time: '00:00',
      localTimeZone: new LocalZone().name
    }
  },
  computed: {
    localDateTime: {
      get () {
        return this.dateTimeString
      },
      set (dateTimeString) {
        this.$emit('update:date-time-string', dateTimeString)
      }
    },
    dateTime () {
      return DateTime
        .local()
        .setZone(this.timeZone)
        .setLocale(this.$i18n.locale)
    },
    formattedDateTime () {
      return this.localDateTime
        ? DateTime
          .fromISO(this.localDateTime, { zone: this.timeZone })
          .toFormat('yyyy-MM-dd - HH:mm')
        : null
    },
    tzHint () {
      return this.hint
        ? this.$t(this.hint)
        : `${this.dateTime.zoneName} - ${this.dateTime.offsetNameShort} (${this.dateTime.offsetNameLong})`
    },
    customTimezone () {
      return this.timeZone !== this.localTimeZone
    }
  },
  methods: {
    updateLocalDateTime () {
      this.localDateTime = `${this.date}T${this.time}`
    },
    resetLocalState (menuState) {
      if (menuState && this.localDateTime) {
        const dateTimeArray = this.localDateTime.split('T')
        this.date = dateTimeArray[0]
        this.time = dateTimeArray[1]
      }
    }
  }
}
</script>
